import { Box, BoxProps, Flex, Grid, Heading, Text } from '@chakra-ui/react';

import { ContentContainer } from './container';
import { Glyph } from './glyph';
export type UdacityOutcomesSectionProps = {
  containerProps?: BoxProps;
};
export function UdacityOutcomesSection({ containerProps }: UdacityOutcomesSectionProps) {
  return (
    <Box
      as='section'
      padding={{
        base: '40px 0px',
        md: '48px 0px 80px',
      }}
      {...containerProps}
    >
      <ContentContainer>
        <Heading as='h2' marginBottom='24px' size='h3' textAlign='center'>
          Job-ready talent. Superior outcomes.
        </Heading>
        <Grid
          as='ul'
          gridTemplateColumns='repeat(auto-fill, minmax(256px, 1fr))'
          gridGap={{
            base: '14px',
            md: '32px',
          }}
        >
          <Flex alignItems='center' as='li' gap='8px'>
            <Glyph height={64} name='paper-checkmark-two' width={64} />
            <Text flex={{ md: '0 1 184px' }}>Curriculum co-created with industry leaders</Text>
          </Flex>
          <Flex alignItems='center' as='li' gap='8px'>
            <Glyph height={64} name='checkmark-medal-two' width={64} />
            <Text flex={{ md: '0 1 184px' }}>Personalized feedback ensures mastery</Text>
          </Flex>
          <Flex alignItems='center' as='li' gap='8px'>
            <Glyph height={64} name='tools-tools-two' width={64} />
            <Text flex={{ md: '0 1 184px' }}>Hands-on projects, not step-by-step guides</Text>
          </Flex>
          <Flex alignItems='center' as='li' gap='8px'>
            <Glyph height={64} name='location-network-location-one' width={64} />
            <Text flex={{ md: '0 1 184px' }}>700+ highly vetted expert network</Text>
          </Flex>
        </Grid>
      </ContentContainer>
    </Box>
  );
}
