import { Fragment } from 'react';

import { Box, BoxProps, Flex, FlexProps, Heading } from '@chakra-ui/react';

import { SanityImageType } from '~/features/page-template/models/image';

import { ContentContainer } from './container';
import { SanityImage } from './sanity-image';

export type LogosSectionProps = {
  title: string;
  containerProps?: BoxProps;
  companies: Array<{
    _id: string;
    name: string;
    logo: SanityImageType | null;
  }>;
  logoContainerProps?: FlexProps;
};

export function LogosSection({ title, containerProps, companies = [], logoContainerProps }: LogosSectionProps) {
  if (!companies?.length) return null;
  return (
    <Box
      as='section'
      padding={{
        base: '40px 0px',
        md: '48px 0px',
      }}
      {...containerProps}
    >
      <ContentContainer
        alignItems='center'
        display='flex'
        flexDir={{
          base: 'column',
          md: 'row',
        }}
        gap={{
          md: '16px',
        }}
      >
        <Box
          marginRight='auto'
          flex={{
            md: '0 1 300px',
          }}
        >
          <Heading
            as='h2'
            size='h2'
            marginBottom={{
              base: '40px',
              md: 'unset',
            }}
            textAlign={{
              base: 'center',
              md: 'left',
            }}
          >
            {title}
          </Heading>
        </Box>
        <Flex
          flexWrap='wrap'
          gap='56px'
          flex={{
            md: '0 1 736px',
          }}
          {...logoContainerProps}
        >
          {companies.map((company) => (
            <Fragment key={company._id}>
              {company.logo && <SanityImage alt={company.name} image={company.logo} />}
            </Fragment>
          ))}
        </Flex>
      </ContentContainer>
    </Box>
  );
}
