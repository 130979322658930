import { MouseEvent, ReactNode } from 'react';
import Link from 'next/link';

import { Box, BoxProps, Flex, Grid, Heading, Text } from '@chakra-ui/react';
import { ArrowRightIcon } from '@udacity/chakra-uds-icons';

import { HeadingWithEmphasis, HeadingWithEmphasisProps } from '~/components/heading-with-emphasis';
import { SanityImageType } from '~/features/page-template/models/image';

import { Card } from './card';
import { ContentContainer } from './container';
import { SanityImage } from './sanity-image';
import { Space } from './space';

type School = {
  _id: string;
  title: string;
  slug: string;
  graphic2: SanityImageType;
};

export type SchoolsCardSectionProps = {
  title: string;
  description?: string;
  schools: School[];
  onClickSchool?: (school: School, e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => void;
  containerProps?: BoxProps;
  headingWithEmphasisProps?: Omit<HeadingWithEmphasisProps, 'text'>;
  backgroundSlot?: ReactNode;
};

export function SchoolsCardSection({
  title,
  description,
  schools,
  onClickSchool,
  containerProps,
  headingWithEmphasisProps,
  backgroundSlot = null,
}: SchoolsCardSectionProps) {
  return (
    <Box as='section' padding={{ base: '40px 0px', md: '80px 0px' }} position='relative' {...containerProps}>
      {backgroundSlot}
      <ContentContainer position='relative'>
        <Flex flexDir='column' gap='8px' textAlign='center'>
          <HeadingWithEmphasis
            emphasisProps={{
              color: 'blue.500',
              fontWeight: '600',
            }}
            headingProps={{
              as: 'h2',
              size: 'marketing-5xl',
            }}
            {...headingWithEmphasisProps}
            text={title}
          />
          {description && <Text>{description}</Text>}
        </Flex>
        <Space />
        <Grid
          gridGap='32px'
          gridTemplateColumns={{
            base: 'unset',
            sm: 'repeat(auto-fill, minmax(352px, 1fr))',
          }}
        >
          {schools.map((school) => (
            <Card
              key={school._id}
              as='article'
              backgroundColor='blue.800'
              borderRadius='8px 24px'
              color='white'
              display='flex'
              overflow='hidden'
            >
              <Flex
                as={Link}
                flexDir='column'
                href={`/school/${school.slug}`}
                width='100%'
                onClick={(e) => onClickSchool?.(school, e)}
              >
                {school.graphic2 && (
                  <Box flex='0 0 192px' position='relative'>
                    <SanityImage
                      alt=''
                      image={school.graphic2}
                      quality={75}
                      sizes='508px'
                      style={{
                        objectFit: 'cover',
                        objectPosition: 'center',
                        pointerEvents: 'none',
                      }}
                      fill
                    />
                  </Box>
                )}
                <Flex flex='1' flexDir='column' padding='24px'>
                  <Heading as='p' size='marketing-2xl'>
                    {school.title}
                  </Heading>
                  <Text color='accent.sea-foam.500' fontWeight='700' marginTop='auto' textDecor='underline'>
                    Learn more
                    <ArrowRightIcon />
                  </Text>
                </Flex>
              </Flex>
            </Card>
          ))}
        </Grid>
      </ContentContainer>
    </Box>
  );
}
