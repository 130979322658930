import { useMemo } from 'react';

import { Box, BoxProps, Heading, HeadingProps } from '@chakra-ui/react';

export type HeadingWithEmphasisProps = {
  text: string;
  headingProps?: Omit<HeadingProps, 'children'>;
  emphasisProps?: BoxProps;
};

export function HeadingWithEmphasis({ text, headingProps, emphasisProps }: HeadingWithEmphasisProps) {
  const [start = null, emphasized = null, end = null] = useMemo(() => {
    return text?.split(/\*\*([a-zA-Z0-9\s].+)\*\*/) || []; // Example: "Top **Schools**"
  }, [text]);
  return (
    <Heading {...headingProps}>
      {start}
      {emphasized && (
        <Box as='span' {...emphasisProps}>
          {emphasized}
        </Box>
      )}
      {end}
    </Heading>
  );
}
